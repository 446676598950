import React from "react";
import {
    Box,
    Grid,
    Popover,
    Container,
    Typography
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,

    QuestionAnswer as QuestionAnswerIcon,
    People as PeopleIcon,
    PeopleAlt as PeopleAltIcon,
    Message as MessageIcon,
    ShoppingCart as AuctionIcon
} from "@mui/icons-material";
import {Link, withRouter} from "react-router-dom";
import clsx from "clsx";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

class Header extends React.PureComponent {

    _menu = () => {
        const mathPath = this.props?.match?.path || "";

        return [
            {
                Icon: QuestionAnswerIcon,
                label: "Question answer",
                path: "/faq",
                isActive: Boolean(mathPath.indexOf("/faq") > -1)
            },
            {
                Icon: PeopleIcon,
                label: "Users",
                path: "/users",
                isActive: Boolean(mathPath.indexOf("/users") > -1)
            },
            {
                Icon: MessageIcon,
                label: "Feedback",
                path: "/messages",
                isActive: Boolean(mathPath.indexOf("/messages") > -1)
            },
            {
                Icon: MessageIcon,
                label: "Metafluencers",
                path: "/metafluencers",
                isActive: Boolean(mathPath.indexOf("/metafluencers") > -1)
            },
            {
                Icon: PeopleAltIcon,
                label: "Team",
                path: "/team",
                isActive: Boolean(mathPath.indexOf("/team") > -1)
            },
            {
                Icon: AuctionIcon,
                label: "Auction",
                path: "/auction",
                isActive: Boolean(mathPath.indexOf("/auction") > -1)
            },
        ]
    }

    render() {
        const {
            classes
        } = this.props;

        return (
            <Box className={classes.root}>
                <Grid container>

                        <Grid item>
                            <Box className={classes.logo}>
                                <Box className={classes.logoLabel}>Meta Fluencer</Box>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Grid container spacing={2}>
                                {
                                    this._menu().map((item, index) => (
                                        <Grid item key={`menu-item-${index}`}>

                                            {Boolean((item.items || []).length <= 0) && (
                                                <Link to={item.path}>
                                                    <Box className={clsx({
                                                        [classes.menuItem]: true,
                                                        [classes.menuItemDisable]: Boolean(item.path === "#"),
                                                    })}>
                                                        <Box className={classes.menuItemIcon}>
                                                            <item.Icon/>
                                                            <img src={item.iconPath} />
                                                        </Box>
                                                        <Typography className={classes.menuItemLabel}>{item.label}</Typography>

                                                        {Boolean(item.isActive) && (
                                                            <Box className={classes.menuItemIndicator} />
                                                        )}
                                                    </Box>
                                                </Link>
                                            )}

                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>

                    </Grid>
            </Box>
        );
    }
}

const styles = {
    root: {
        backgroundColor: "white",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)"
    },

    logo: {
        display: "flex"
    },
    logoLabel: {
        height: 64,
        backgroundColor: "#7000FF",
        padding: "0 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 20,

        fontSize: 24,
        lineHeight: "24px",
        color: "white",
        fontWeight: "600"
    },
    logoIcon: {},

    menuItem: {
        display: "flex",
        alignItems: "center",
        height: 64,
        position: "relative"
    },
    menuItemDisable: {
        opacity: 0.4,
        cursor: "not-allowed"
    },
    menuItemIcon: {
        width: 24,
        height: 24
    },
    menuItemLabel: {
        fontSize: 16,
        lineHeight: "26px",
        fontWeight: "600",
        color: "#22252D",
        marginLeft: 8
    },
    menuItemIndicator: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#7000FF",
        height: 5
    },

    dropDownItem: {
        padding: "8px 16px"
    }
}

const headerStyles = withStyles(styles)(Header);
export default withRouter(headerStyles)
