import React from 'react';
import {Dialog, DialogContent, DialogTitle, List, ListItem, Typography} from "@mui/material";

const DialogWindowHashes = ({isOpen, hashes, handleClose}) => {

    const handleCloseModal = () => {
        handleClose();
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={isOpen}

            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h2">
                    Transactions
                </Typography>
            </DialogTitle>

            <DialogContent>
                <List sx={{
                    maxHeight: 440,
                }}>
                    {hashes.map(hash => {
                        return (
                            <ListItem>
                                <Typography variant="h3" sx={{
                                    color: "#7000FF",
                                    display: "inline"
                                }}><a target="_blank" href={"https://etherscan.io/tx/"+hash}>{hash}</a></Typography>
                            </ListItem>
                        )
                    })}
                </List>
                {/*<ul>*/}
                {/*    {hashes.map(hash => {*/}
                {/*        return (*/}
                {/*            <li>*/}
                {/*                <Typography variant="h2" sx={{*/}
                {/*                    color: "#7000FF",*/}
                {/*                    display: "inline"*/}
                {/*                }}>{hash}</Typography>*/}
                {/*            </li>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</ul>*/}
            </DialogContent>

        </Dialog>
    );
};

export default DialogWindowHashes;