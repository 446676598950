import React, {useRef} from 'react';
import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";

const initialValues = {
    address: '',
}

const DialogFormOutput = ({isOpen, handleClose, outputBalance}) => {

    const refFormik = useRef(null);

    const onSubmit = () => {
        const data = refFormik.current.values;
        outputBalance(data);
        handleCloseModal();
    }

    const handleCloseModal = () => {
        handleClose();
    }

    const changeForm = ({target}) => {
        const {
            name,
            value
        } = target;

        let newForm = {...refFormik.current.values};
        newForm[name] = value;
        refFormik.current.setValues(newForm);
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}

            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">
                    Withdraw balance
                </Typography>
            </DialogTitle>

            <DialogContent>

                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit,
                        } = props;

                        return (
                            <>
                                <Box mt={1}/>

                                <Box>
                                    <TextField
                                        value={values.address}
                                        error={Boolean(touched.address && errors.address)}
                                        helperText={touched.address && errors.address}
                                        name="address"
                                        label="Address"
                                        placeholder="Enter"
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeForm}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <Grid container alignItems="flex-end">
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={handleCloseModal}
                                                >Cancel</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={handleSubmit}
                                                >
                                                    Withdraw
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </>
                        )
                    }}
                </Formik>

            </DialogContent>

        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    address: Yup.string().required('Fill in'),
});

export default DialogFormOutput;