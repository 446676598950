import React, {useRef} from 'react';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import FileUpload from "react-material-file-upload";
import * as Yup from "yup";

const initialValues = {
    name: '',
    description: '',
    picture: [],
    expiration: '',
    floorPrice: ''
}

const DialogFormCreateAuction = ({isOpen, handleClose, createAuction}) => {

    const refFormik = useRef(null);

    const onSubmit = () => {
        const data = refFormik.current.values;
        data.floorPrice = Number(data.floorPrice);
        data.expiration = Number(data.expiration);
        createAuction(data);
        handleCloseModal();
    }

    const handleCloseModal = () => {
        handleClose();
    }

    const changeForm = ({target}) => {
        const {
            name,
            value
        } = target;

        let newForm = {...refFormik.current.values};
        newForm[name] = value;
        refFormik.current.setValues(newForm);
    }
    const changeFormFiles = (files, name) => {
        let newForm = {...refFormik.current.values};
        newForm[name] = files;
        refFormik.current.setValues(newForm);
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}

            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">
                    Create auction
                </Typography>
            </DialogTitle>

            <DialogContent>

                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit,
                        } = props;

                        return (
                            <>
                                <Box mt={1}/>

                                <Box>
                                    <TextField
                                        value={values.name}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                        name="name"
                                        label="Name"
                                        placeholder="Enter"
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeForm}
                                    />
                                </Box>

                                <Box mt={2}>
                                    <TextField
                                        value={values.description}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                        name="description"
                                        label="Description"
                                        placeholder="Enter"
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeForm}
                                    />
                                </Box>

                                <Box mt={2}>

                                    <Typography variant="subtitle1">Picture</Typography>

                                    <FileUpload
                                        value={values.picture}
                                        accept="image/*"
                                        name="picture"
                                        multiple={false}
                                        onChange={(files) => changeFormFiles(files, "picture")}
                                    />

                                    {Boolean(touched.picture && errors.picture) && (
                                        <FormHelperText error>
                                            {touched.picture && errors.picture}
                                        </FormHelperText>
                                    )}
                                </Box>

                                <Box mt={2}>
                                    <TextField
                                        value={values.expiration}
                                        error={Boolean(touched.expiration && errors.expiration)}
                                        helperText={touched.expiration && errors.expiration}
                                        name="expiration"
                                        label="Expiration"
                                        placeholder="Enter"
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeForm}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <TextField
                                        value={values.floorPrice}
                                        error={Boolean(touched.floorPrice && errors.floorPrice)}
                                        helperText={touched.floorPrice && errors.floorPrice}
                                        name="floorPrice"
                                        label="Floor Price"
                                        placeholder="Enter"
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeForm}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <Grid container alignItems="flex-end">
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={handleCloseModal}
                                                >Close</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={handleSubmit}
                                                >
                                                   Create
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </>
                        )
                    }}
                </Formik>

            </DialogContent>

        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Fill in'),
    description: Yup.string().required('Fill in'),
    picture: Yup.array().length(1, "Fill in"),
    expiration: Yup.number().typeError('Fill in the numbers').required('Fill in'),
    floorPrice: Yup.number().typeError('Fill in the numbers').required('Fill in')
});

export default DialogFormCreateAuction;