import React from "react";
import {
    Header as HeaderComponent
} from "./components";
import {
    Box
} from "@mui/material";

class Default extends React.Component {
    render () {
        return (
            <>

                <HeaderComponent/>

                <Box height="calc(100vh - 64px)" overflow="auto" px={2} py={1} boxSizing="border-box">
                    { this.props.children }
                </Box>
            </>
        )
    }
}

export default Default
