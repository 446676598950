import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
    RouteWithLayout
} from "../components";
import {
    Default as DefaultLayout
} from "../layouts";
import {
    Faq as FaqPage,
    Users as UsersPage,
    Messages as MessagesPage,
    Team as TeamPage,
    Metafluencers as MetafluencersPage,
    Auction as AuctionPage
} from "../pages";

const pages = [
    {
        path: '/faq',
        component: FaqPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/users',
        component: UsersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/messages',
        component: MessagesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/team',
        component: TeamPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/metafluencers',
        component: MetafluencersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/auction',
        component: AuctionPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
];

const MainRoutes = () => {
    return (
        <Switch>
            {
                pages.map((page, idx) => (
                    <RouteWithLayout
                        key={'page-' + idx}
                        {...page}
                    />
                ))
            }

            <Redirect to="/faq"/>
        </Switch>
    );
};

export default MainRoutes;
