import React from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Box,
    Grid,
    Checkbox,
    Pagination
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import moment from "moment";

const TableCustom = (props) => {
    const {
        data,
        pagination,

        isLoading,

        onChangePagination,
        onSetUserAdmin
    } = props;


    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell align="right">Date create</TableCell>
                        <TableCell align="right">Date update</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>

                                        </TableRow>
                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (
                                        <TableRow>
                                            <TableCell>
                                                { row.id }
                                            </TableCell>
                                            <TableCell>
                                                { row.address }
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={row.admin}
                                                    onChange={() => onSetUserAdmin(row)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                { moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss") }
                                            </TableCell>
                                            <TableCell align="right">
                                                { moment(row.updatedAt).format("DD.MM.YYYY HH:mm:ss") }
                                            </TableCell>
                                        </TableRow>
                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={2}>
                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <Pagination
                            page={Number(pagination.page)}
                            count={Number(pagination.total)}

                            onChange={handleChangePage}
                        />
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}

const useStyles = makeStyles(() => ({
    buttonTakeWorkOrder: {
        textTransform: "initial",
        fontSize: 14,
        padding: "4px 12px",
        height: "auto",
        borderRadius: 8,
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    }
}));

export default TableCustom
