import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {setUser} from '../states/global';
import {

} from "../components";

import MainRoute from "./Main";
import NotAuth from "./NotAuth";
import agent from "../agent/agent";
import urls from "../variables/urls";
import {getCookie} from "../helper/cookie";
import agentTgBot from "../agent/agentTgBot";

class Router extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount = async () => {
        await this.getUser();
    }

    getUser = async () => {
        const token = localStorage.getItem('jwt');

        if (!token) {
            this.setState({isLoading: false});

            return null
        }

        localStorage.setItem('jwt', token);
        agent.defaults.headers["Authorization"] = token;
        agentTgBot.defaults.headers["Authorization"] = token;

        const account = await agent.get(urls.userMe).then((res) => {
            return res.data?.user
        }).catch((err) => {
            return {}
        })
        if (!account.admin) {
            this.setState({isLoading: false});

            localStorage.removeItem('jwt');
            agent.defaults.headers["Authorization"] = "";
            agentTgBot.defaults.headers["Authorization"] = "";

            return null
        }

        this.props.setUser(account);
        this.setState({isLoading: false});
    }

    render() {
        const {isLoading} = this.state;
        const {user} = this.props.global;

        if (isLoading) {
            return null
        }

        if (!user) {
            return (
                <NotAuth/>
            )
        }

        return (
            <>
                <MainRoute/>
            </>
        )
    }
}

export default compose(
    connect(
        state => ({
            global: state.global
        }),
        dispatch => ({
            setUser: (user) => dispatch(setUser(user))
        }),
    ),
)(Router);
