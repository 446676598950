import React from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Box,
    Grid,
    Checkbox,
    Pagination
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import moment from "moment";

const TableCustom = (props) => {
    const {
        data,
        pagination,

        isLoading,

        onChangePagination
    } = props;


    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell align="right">Date created</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>

                                        </TableRow>
                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (
                                        <TableRow>
                                            <TableCell>{ row.id }</TableCell>
                                            <TableCell>{ row.name }</TableCell>
                                            <TableCell>{ row.email }</TableCell>
                                            <TableCell>{ row.phone }</TableCell>
                                            <TableCell>{ row.message }</TableCell>
                                            <TableCell align="right">
                                                {moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                                            </TableCell>
                                        </TableRow>
                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={2}>
                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <Pagination
                            page={Number(pagination.page)}
                            count={Number(pagination.total)}

                            onChange={handleChangePage}
                        />
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}

const useStyles = makeStyles(() => ({
    buttonTakeWorkOrder: {
        textTransform: "initial",
        fontSize: 14,
        padding: "4px 12px",
        height: "auto",
        borderRadius: 8,
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    }
}));

export default TableCustom
