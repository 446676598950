import {palette} from "../common";

export default {
    styleOverrides: {

        root: {

            "& .MuiPaginationItem-root": {
                height: 39,
                minWidth: 39,
                padding: "0 10px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                borderRadius: 5,

                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                fontSize: 16,
                lineHeight: "16px",
                color: "#22252D",
                fontFeatureSettings: "'ss03' on, 'ss06' on",
                letterSpacing: "0.02em"
            },
            "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#7000FF",

                color: "white",
                fontWeight: "600"
            },

        }
    }
}
