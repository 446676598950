import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    FormHelperText,
    Typography,
    TextField,
    Button,
    Grid,
    Box
} from "@mui/material";
import {} from "@mui/styles";
import {
    Formik
} from "formik";
import FileUpload from "react-material-file-upload";
import * as Yup from "yup";

const initForm = {
    "index": 1,
    "picture": [],
};

class DialogForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            form: {...initForm},

            isOpen: false,
            isCreate: false,

            onSubmit: null
        }

        this.refFormik = React.createRef();
    }

    open = ({form, onSubmit, isCreate}) => {
        this.setState({
            onSubmit,
            isCreate,
            isOpen: true
        }, () => {
            if (isCreate) {
                return
            }

            setTimeout(() => {
                this.refFormik.current.setValues(form);
            }, 1000)
        })
    }
    close = () => {
        this.setState({
            isOpen: false
        })
    }

    // Логика работы с формой
    onSubmit = (form) => {
        this.state.onSubmit(form, true)
    }
    changeForm = ({ target }) => {
        const {
            name,
            value
        } = target;

        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;
        this.refFormik.current.setValues(newForm);
    }
    changeFormFiles = (files, name) => {
        let newForm = {...this.refFormik.current.values};
        newForm[name] = files;
        this.refFormik.current.setValues(newForm);
    }

    // Вспомогательные функции
    _dialogTitle = () => {
        if (this.state.isCreate) {
            return "Created"
        }

        return `Edited`
    }

    render() {
        const {
            form,

            isOpen,
            isCreate
        } = this.state;

        return (
            <Dialog
                open={isOpen}
                fullWidth

                maxWidth="sm"

                onClose={this.close}
            >

                <DialogTitle>
                    <Typography variant="h3">
                        {this._dialogTitle()}
                    </Typography>
                </DialogTitle>

                <DialogContent>

                    <Formik
                        innerRef={this.refFormik}
                        initialValues={form}
                        validationSchema={validationSchema}
                        onSubmit={this.onSubmit}
                    >
                        {(props) => {
                            const {
                                values,
                                errors,
                                touched,
                                handleSubmit,
                            } = props;

                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                >

                                    <Box mt={1}/>

                                    <Box>
                                        <TextField
                                            value={values.index}
                                            error={Boolean(touched.index && errors.index)}
                                            helperText={touched.index && errors.index}
                                            name="index"
                                            label="Index"
                                            placeholder="Enter"
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            onChange={this.changeForm}
                                        />
                                    </Box>

                                    <Box mt={2}>

                                        <Typography variant="subtitle1">Picture</Typography>

                                        <FileUpload
                                            value={values.picture}
                                            accept="image/*"
                                            name="picture"
                                            multiple={false}
                                            onChange={(files) => this.changeFormFiles(files, "picture")}
                                        />

                                        {Boolean(touched.picture && errors.picture) && (
                                            <FormHelperText error>
                                                {touched.picture && errors.picture}
                                            </FormHelperText>
                                        )}
                                    </Box>

                                    <Box mt={2}>
                                        <Grid container alignItems="flex-end">
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="outlined"
                                                        fullWidth
                                                        onClick={this.close}
                                                    >Close</Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={handleSubmit}
                                                    >
                                                        {isCreate ? "Create" : "Edit"}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </form>
                            )
                        }}
                    </Formik>

                </DialogContent>

            </Dialog>
        );
    }
}

const validationSchema = Yup.object().shape({
    index: Yup.string().required('Fill in'),
    picture: Yup.array().length(1, "Fill in"),
});

export default DialogForm
