import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_TG_BOT_HOST_API,
    headers: {
        'Authorization': localStorage.getItem('token')
    }
})

export default axiosInstance
