import React, {Component} from 'react';
import {Backdrop, CircularProgress} from "@mui/material";
import TableComponent from "./components/Table";
import agent from "../../../agent/agent";
import DialogFormCreateAuction from "./components/DialogFormCreateAuction";
import AuctionInformation from "./components/AuctionInformation";
import {DialogConfirmAction} from "../../../components";
import {imageToBase64} from "../../../helper/image";
import {Notification, NotificationTypes} from "../../../common/Notification";
import DialogFormOutput from "./components/DialogFormOutput";
import DialogWindowHashes from "./components/DialogWindowHashes";

class Auction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            balance: 0,
            hashes: [],

            pagination: {
                page: 1,
                total: 1,
            },

            isOpenCreate: false,
            isOpenOutput: false,
            isOpenHashes: false,
            isLoading: true,
            isShowBackdrop: false,
        }
    }

    componentDidMount = async () => {
        await this.getData();
        await this.getBalance();
    }

    // Получение аукционов
    getData = async () => {
        const response = await agent.get(`/auction/items?all=1&page=1&limit=999`).then((res) => {
            return res.data?.auctionItems || []
        }).catch(() => {
            return []
        })

        this.setState({
            data: response,
            isLoading: false
        });

        this.refDialogConfirmAction = React.createRef();
    }

    //Логика создания аукциона
    createData = async (data, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Confirmation",
                message: "Are you sure you want to create the auction item?",
                labelSuccess: "Yes, create",
                onSuccess: this.createData.bind(this, data, true),
            })
            return
        }

        this.setState({isShowBackdrop: true})

        const body = {
            ...data,
            picture: await imageToBase64(data?.picture?.[0])
        }

        const response = await agent.post(`/auction/items?all=1&page=1&limit=999`, body).then((res) => {
            return true
        }).catch((err) => {
            return {error: err.response || true}
        })
        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Error network",
                type: NotificationTypes.error
            })

            return
        }

        await this.getData();

        Notification({
            message: "Successfully",
            type: NotificationTypes.success
        })

        this.setState({isShowBackdrop: false});
    }

    //Удаление аукциона
    deleteData = async (row, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Confirmation",
                message: "Are you sure you want to delete the entry?",
                labelSuccess: "Yes, delete",
                onSuccess: this.deleteData.bind(this, row, true),
            })

            return
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.delete(`/auction/items/${row.id}`).then((res) => {
            return true
        }).catch((error) => {
            return {error: error.response}
        })
        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.message,
                type: NotificationTypes.error
            })

            return
        }

        await this.getData();

        Notification({
            message: "Successfully",
            type: NotificationTypes.success
        })

        this.setState({isShowBackdrop: false});
    }

    //Получение общего баланса
    getBalance = async () => {
        const response = await agent.get(`/auction/wallets/balance`)
            .then((res) => {
                return res.data.balance || 0;
            })
            .catch(() => {
                return 0;
            })

        this.setState({
            balance: response,
        })
    }

    //Вывод баланса
    outputBalance = async (data, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Confirmation",
                message: "Are you sure you want to withdraw the balance?",
                labelSuccess: "Yes, withdraw",
                onSuccess: this.outputBalance.bind(this, data, true),
            })
            return
        }

        this.setState({isShowBackdrop: true})

        const body = {
            ...data,
        }

        const response = await agent.post(`/auction/wallets/withdraw`, body).then((res) => {
            return res.data?.hashes;
        }).catch((err) => {
            return {error: err.response || true}
        })
        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Error network",
                type: NotificationTypes.error
            })

            return
        }

        await this.getBalance();

        Notification({
            message: "Successfully",
            type: NotificationTypes.success
        })

        this.setState({hashes: response});

        this.setState({isShowBackdrop: false});

    }

    //Открытие формы создания
    setIsOpenCreate = (isOpen) => {
        this.setState({isOpenCreate: isOpen});
    }
    //Закрытие формы создания
    handleCloseCreate = () => {
        this.setIsOpenCreate(false);
    }

    //Открытие формы вывода баланса
    setIsOpenOutput = (isOpen) => {
        this.setState({isOpenOutput: isOpen});
    }

    //Закрытие формы вывода баланса
    handleCloseOutput = () => {
        this.setIsOpenOutput(false);
    }

    //Открытие формы с хэшами
    setIsOpenHashes = (isOpen) => {
        this.setState({isOpenHashes: isOpen});
    }

    //Закрытие формы с хэшами
    handleCloseHashes = () => {
        this.setIsOpenHashes(false);
    }

    render() {
        const {
            data,
            balance,
            hashes,
            isOpenHashes,
            pagination,
            isLoading,
            isOpenCreate,
            isOpenOutput,
            isShowBackdrop,
        } = this.state;
        const {} = this.props;
        return (
            <>

                <AuctionInformation balance={balance} setIsOpenCreate={this.setIsOpenCreate}
                                    setIsOpenOutput={this.setIsOpenOutput} hashes={hashes}
                                    setIsOpenHashes={this.setIsOpenHashes}
                />

                <TableComponent
                    data={data}
                    isLoading={isLoading}
                    deleteAuction={this.deleteData}
                />

                <DialogFormCreateAuction isOpen={isOpenCreate} handleClose={this.handleCloseCreate}
                                         createAuction={this.createData}/>

                <DialogFormOutput isOpen={isOpenOutput} handleClose={this.handleCloseOutput}
                                  outputBalance={this.outputBalance}/>

                <DialogWindowHashes hashes={hashes} isOpen={isOpenHashes} handleClose={this.handleCloseHashes}/>

                <DialogConfirmAction ref={this.refDialogConfirmAction}/>

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
}

export default Auction;