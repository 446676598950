import React, {Component} from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import {
    LoadingButton
} from "@mui/lab";
import {
    withStyles
} from "@mui/styles";
import {
    Formik
} from "formik";
import * as Yup from "yup";
import {
    Notification,
    NotificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import errorAuth from "../../../errors/messages/errorAuth";
import agentTgBot from "../../../agent/agentTgBot";

class Authorization extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                address: ""
            },

            verificationId: null,
            stage: 1,

            isLoading: false
        };

        this.refFormik = React.createRef();
    }

    componentDidMount = () => {
    }

    loginFromMetaMask = async () => {
        if (!window.ethereum) {
            Notification({
                type: NotificationTypes.error,
                message: "MetaMask не установлен"
            })
        }

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        let form = {...this.state.form};
        form.address = accounts[0];
        await this.setState({ form })

        await this.onSubmit();
    }

    onSubmit = async () => {
        if (this.state.isLoading) {
            return null
        }

        await this.onAuth();
    }
    onAuth = async () => {
        this.setState({isLoading: true});

        // const form = {...this.refFormik.current.values};
        const form = {...this.state.form};

        const response = await agent.post(urls.userAuth, {
            address: form.address
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err?.response}
        });
        if (response.error) {
            this.setState({isLoading: false});

            let errorMessage = "Неправильный ЛОГИН / ПАРОЛЬ";

            Notification({
                type: NotificationTypes.error,
                message: errorMessage
            })

            return null
        }

        localStorage.setItem('jwt', response.jwt);
        agent.defaults.headers["Authorization"] = response.jwt;
        agentTgBot.defaults.headers["Authorization"] = response.jwt;

        const account = await agent.get(urls.userMe).then((res) => {
            return res.data?.user
        }).catch((err) => {
            return {}
        });
        if (!account?.admin) {
            this.setState({isLoading: false});

            localStorage.removeItem('jwt');
            agent.defaults.headers["Authorization"] = "";
            agentTgBot.defaults.headers["Authorization"] = "";

            Notification({
                type: NotificationTypes.error,
                message: `On account "${ form.address }" no rights`
            })

            return null
        }

        this.props.setUser(account);

        this.setState({isLoading: false});
    }

    changeForm = ({target}) => {
        const {name, value} = target;
        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    }

    render() {
        const {
            form,

            isLoading
        } = this.state;
        const {
            classes
        } = this.props;

        return (
            <Box display="flex" flexDirection="column" alignItems="center">

                <Box mb={8}>
                    <Typography variant="h1">Authorization</Typography>
                </Box>

                <Box flex="1" display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                     height="100%">

                    <Box width={640} px={8} py={4} className={classes.content}>
                        <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={this.loginFromMetaMask}
                        >
                            Login with MetaMask
                        </Button>
                    </Box>
                </Box>

            </Box>
        );
    }
}

const validationSchema = Yup.object().shape({
    address: Yup.string().required('Fill in the field')
});
const styles = {
    content: {
        background: "rgba(0, 0, 0, 0.03)",
        backdropFilter: "blur(50px)",
        borderRadius: 10
    },

    button: {}
};

export default withStyles(styles)(Authorization)
