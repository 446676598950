import React from 'react';
import {Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {Skeleton} from "@mui/lab";

const TableComponent = ({data, isLoading, deleteAuction}) => {
    return (<>
        <Table>

            <TableHead>
                <TableRow>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center" Width='238px'>Description</TableCell>
                    <TableCell align="center">Picture</TableCell>
                    <TableCell align="center">Bids</TableCell>
                    <TableCell align="center">Bids sum</TableCell>
                    <TableCell align="center">Floor price</TableCell>
                    <TableCell align="center">Winner</TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>

            <TableBody>

                {

                    Boolean(isLoading) ? (

                        <>

                            {[0, 1, 2, 3, 4, 5].map((item) => (<TableRow key={`row-item-load-${item}`}>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                            </TableRow>))}

                        </>

                    ) : (

                        <>

                            {

                                data.map((row, index) => (<TableRow>
                                    <TableCell align="center">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.description}
                                    </TableCell>
                                    <TableCell align="center">
                                        <img
                                            src={row.picture}
                                            style={{
                                                width: 80, height: 80
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.auctionBids.length}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.auctionBids.reduce((acc, curr) => acc += Number(curr.amount), 0)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.balance}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.auctionBids.map(bid => {
                                            return bid.winner ? bid.user.address : '-';
                                        })}
                                    </TableCell>
                                    {row.auctionBids.length > 0 ? <TableCell/> : <TableCell align="center">
                                        <Grid container alignItems="center" justifyContent="flex-end"
                                              wrap="nowrap">
                                            <Grid item>
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => deleteAuction(row)}>
                                                        <DeleteIcon color="error"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </TableCell>}
                                </TableRow>))

                            }

                        </>

                    )

                }

            </TableBody>

        </Table>
    </>);
};

export default TableComponent;