import React from "react";
import {
    Box,
    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import PropTypes from 'prop-types';


const AbsoluteInformationMessage = (props) => {
    const {
        message,

        topOffset,
        leftOffset,
        rightOffset,
        bottomOffset
    } = props;
    const classes = useStyles();


    const _configurationRootSx = () => {
        return {
            top: topOffset || 0,
            left: leftOffset || 0,
            right: rightOffset || 0,
            bottom: bottomOffset || 0,
        }
    }

    return (
        <Box
            className={classes.root}
            sx={_configurationRootSx()}
        >

            <Box className={classes.messageContent}>
                <Typography className={classes.message}>
                    { message }
                </Typography>
            </Box>

        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "rgba(40, 40, 40, 0.4)",
        backdropFilter: "blur(5px)",
        borderRadius: 14,

        position: "absolute",

        zIndex: 50,

        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    messageContent: {
        backgroundColor: "#FFFFFF",
        borderRadius: 14,
        padding: "16px 64px",
        boxSizing: "border-box",
        maxWidth: 410
    },
    message: {
        fontSize: 16,
        lineHeight: "23px",
        color: "#282828",
        textAlign: "center"
    },
}));

AbsoluteInformationMessage.propTypes = {
    message: PropTypes.string.isRequired,

    topOffset: PropTypes.number,
    leftOffset: PropTypes.number,
    rightOffset: PropTypes.number,
    bottomOffset: PropTypes.number,
};

export default AbsoluteInformationMessage
