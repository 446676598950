import React, { Component } from "react";
import {
    Box,
    Grid,
    Button,
    Typography,

    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    Table as TableComponent,
    DialogForm as DialogFormComponent
} from "./components";
import {
    DialogConfirmAction
} from "../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";

class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],

            pagination: {
                page: 1,
                total: 1
            },

            isLoading: true,
            isBackdrop: false,
        };

        this.refDialogForm = React.createRef();
        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        await this.getData();
    }


    // Получение списка
    getData = async () => {
        const response = await agent.get(`/faq`).then((res) => {
            return res.data?.questions || []
        }).catch(() => {
            return []
        })

        this.setState({
            data: response,
            isLoading: false
        });
    }


    // Создание ворпоса ответа
    createFaq = async (form, isCreate) => {

        if (!isCreate) {
            this.refDialogForm.current.open({
                onSubmit: this.createFaq.bind(this),
                isCreate: true
            });

            return
        }

        this.setState({ isBackdrop: true });

        const body = {
            ...form,
            index: Number(form.index)
        };
        const response = await agent.post(`/faq`, body).then((res) => {
            return true
        }).catch(() => {
            return false
        })

        await this.getData();

        this.refDialogForm.current.close();

        Notification({
            message: "Successfully",
            type: NotificationTypes.success
        })

        this.setState({ isBackdrop: false });

    }

    // Редактирование вопрос ответа
    editFaq = async (form, isEdit) => {

        if (!isEdit) {
            this.refDialogForm.current.open({
                form: {
                    id: form.id,
                    index: form.index,
                    question: form.question,
                    answer: form.answer,
                },
                onSubmit: this.editFaq.bind(this),
                isCreate: false
            });

            return
        }

        const body = {
            ...form,
            index: Number(form.index)
        };
        const response = await agent.put(`/faq/${ form.id }`, body).then((res) => {
            return true
        }).catch(() => {
            return false
        })

        await this.getData();

        this.refDialogForm.current.close();

        Notification({
            message: "Successfully",
            type: NotificationTypes.success
        })

        this.setState({ isBackdrop: false });

    }

    // Удаление
    deleteFaq = async (row, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmAction.current.open({
                title: "Confirmation",
                message: "Are you sure you want to delete the entry?",
                labelSuccess: "Yes, delete",
                onSuccess: this.deleteFaq.bind(this, row, true),
            })

            return
        }

        this.setState({ isBackdrop: true });

        const response = await agent.delete(`/faq/${ row.id }`).then((res) => {
            return true
        }).catch(() => {
            return false
        })

        await this.getData();

        Notification({
            message: "Successfully",
            type: NotificationTypes.success
        })

        this.setState({ isBackdrop: false });

    }


    render() {
        const {
            data,

            isLoading,
            isBackdrop
        } = this.state;

        return (
            <>

                <Box mb={2}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1">Question answer</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: "4px", padding: "4px 32px", textTransform: "initial", height: "auto" }}
                                onClick={this.createFaq}
                            >Create</Button>
                        </Grid>
                    </Grid>
                </Box>

                <TableComponent
                    data={data}
                    isLoading={isLoading}

                    onEdit={this.editFaq}
                    onDelete={this.deleteFaq}
                />

                <DialogFormComponent
                    ref={this.refDialogForm}
                />

                <DialogConfirmAction
                    ref={this.refDialogConfirmAction}
                />

                <Backdrop open={isBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>

            </>
        );
    }
}

export default Faq
