import React from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";

const AuctionInformation = ({balance, hashes, setIsOpenCreate, setIsOpenOutput, setIsOpenHashes}) => {
    return (
        <>
            <Box mb={2}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h1">Auction</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            sx={{
                                borderRadius: "4px",
                                padding: "4px 32px",
                                textTransform: "initial",
                                height: "auto"
                            }}
                            onClick={() => setIsOpenCreate(true)}
                        >Create</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h3">Balance: <Typography variant="h2" sx={{
                            color: "#7000FF",
                            display: "inline"
                        }}>{balance}</Typography></Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            sx={{
                                fontSize: "12px",
                                borderRadius: "4px",
                                padding: "1px 16px",
                                textTransform: "initial",
                                height: "auto",
                            }}
                            onClick={() => setIsOpenOutput(true)}
                        >Withdraw</Button>
                    </Grid>
                    {hashes.length > 0 && <Grid item>
                        <Button
                            variant="outlined"
                            sx={{
                                fontSize: "12px",
                                borderRadius: "4px",
                                padding: "1px 16px",
                                textTransform: "initial",
                                height: "auto",
                            }}
                            onClick={() => setIsOpenHashes(true)}
                        >Show transactions</Button>
                    </Grid>}
                </Grid>
            </Box>
        </>
    );
};

export default AuctionInformation;