import React, {Component} from "react";
import {Box, Grid, Typography} from "@mui/material";
import agent from "../../../agent/agent";
import {Table as TableComponent} from "./components";

class Messages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],

            pagination: {
                page: 1,
                total: 1
            },

            isLoading: true,
            isBackdrop: false,
        };

        this.refDialogForm = React.createRef();
        this.refDialogConfirmAction = React.createRef();
    }

    componentDidMount = async () => {
        await this.getData();
    }

    // Получение списка
    getData = async () => {
        const {
            data,
            count
        } = await agent.get(`/messages?page=${this.state.pagination.page}&limit=20`).then((res) => {
            return {
                data: res.data?.messages || [],
                count: res.data.count || 1
            }
        }).catch(() => {
            return {
                data: [],
                count: 1
            }
        })

        let pagination = {...this.state.pagination};
        pagination.total = Math.ceil(count / 20) || 1;

        this.setState({
            data: data,
            pagination,
            isLoading: false
        });
    }

    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getData();
    }

    render() {
        const {
            data,
            pagination,

            isLoading,
            isBackdrop
        } = this.state;

        return (
            <>

                <Box mb={2}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1">Feedback</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <TableComponent
                    data={data}
                    pagination={pagination}

                    isLoading={isLoading}

                    onChangePagination={this.changePagination}
                />

            </>
        );
    }
}

export default Messages
