import React from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Grid,
    Tooltip,
    IconButton,
} from "@mui/material";
import {
    Delete as DeleteIcon,
    Edit as EditIcon
} from "@mui/icons-material";
import {
    makeStyles
} from "@mui/styles";

const TableCustom = (props) => {
    const {
        data,

        isLoading,

        onDelete,
        onEdit
    } = props;

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Index</TableCell>
                        <TableCell>Question</TableCell>
                        <TableCell>Answer</TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>

                                        </TableRow>
                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (
                                        <TableRow>
                                            <TableCell>
                                                {row.id}
                                            </TableCell>
                                            <TableCell>
                                                {row.index}
                                            </TableCell>
                                            <TableCell>
                                                {row.question}
                                            </TableCell>
                                            <TableCell>
                                                {row.answer}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Grid container alignItems="center" justifyContent="flex-end" wrap="nowrap">
                                                    <Grid item>
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => onEdit(row, false)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => onDelete(row, false)}>
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

        </>
    )
}

const useStyles = makeStyles(() => ({
    buttonTakeWorkOrder: {
        textTransform: "initial",
        fontSize: 14,
        padding: "4px 12px",
        height: "auto",
        borderRadius: 8,
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    }
}));

export default TableCustom
