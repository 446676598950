const imageToBase64 = (file) => new Promise(function (resolve, reject) {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject("");
})
const base64ToImage = (base64) => new Promise(function (resolve, reject) {
    var image = new Image();

    image.src = base64;

    return image
})
const imageListToFile = (imageLink) => new Promise(function (resolve, reject) {
    const fileName = (imageLink || "").split("/").pop().split(".")?.[0] || "";

    fetch(imageLink).then((res) => {
        res.blob().then((blob) => {
            const file = new File([blob], fileName, {type: blob.type})
            resolve(file)
        })
    }).catch(() => {
        return ""
    });
})

export {
    imageToBase64,
    base64ToImage,
    imageListToFile
}
